import React, { useEffect, useState, useLayoutEffect } from "react";
import Banner from "../components/sharedComponents/Banner";
import InsuranceDataModal from "../components/InsuranceDataModal";
import { useNavigate, Navigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Auth from "../utils/auth";
export default function InsuranceData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const userPhone = useSelector((state) => state.imei);
  const userImei = useSelector((state) => state.imei);

  // New request
  // async function getSubscriberInfo() {
  //   console.log("validating subs info man");
  //   const phony = userPhone ? userPhone : localStorage.getItem("phone");
  //   console.log("phone", phony);
  //   try {
  //     const restOperation = post({
  //       apiName: "ppmrestapi",
  //       path: "/insuranceinfo",
  //       options: {
  //         body: {
  //           phoneNumber: userPhone ? userPhone : localStorage.getItem("phone"),
  //           imei: userImei ? userImei : localStorage.getItem("imei"),
  //         },
  //       },
  //     });

  //     const { body } = await restOperation.response;
  //     const response = await body.json();
  //     console.log("lets see ", response);

  //     dispatch({ type: "SET_BRAND", payload: response.device_brand });
  //     localStorage.setItem("brand", response.device_brand);

  //     dispatch({ type: "SET_MODEL", payload: response.device_model });
  //     localStorage.setItem("brand", response.device_brand);

  //     dispatch({
  //       type: "SET_SUBSCRIBER_IMEI",
  //       payload: response.device_imei,
  //     });
  //     localStorage.setItem("subscriberIMEI", response.device_imei);

  //     dispatch({ type: "SET_SUBSCRIBER_NAME", payload: response.firstName });
  //     localStorage.setItem("subscriberName", response.firstName);

  //     dispatch({
  //       type: "SET_SUBSCRIBER_FIRST_LAST_NAME",
  //       payload: response.firstLastName,
  //     });
  //     localStorage.setItem("subscriberFirstLastName", response.firstLastName);

  //     dispatch({
  //       type: "SET_SUBSCRIBER_SECOND_LAST_NAME",
  //       payload: response.secondLastName,
  //     });
  //     localStorage.setItem("subscriberSecondLastName", response.secondLastName);

  //     // console.log("POST call succeeded");
  //     // console.log(response);
  //   } catch (e) {
  //     console.log("POST call failed: ", JSON.parse(e.response.body));
  //     const errorBody = JSON.parse(e.response.body);
  //     console.log("Error body: ", errorBody);

  //     //  if (errorBody.code === "002" || errorBody.code === "003") {
  //     //    setPolicyException(true);
  //     //    setTriggerErrorMessage(errorBody.message);
  //     //  } else if (errorBody.code === "001") {
  //     //    setTriggerError(true);
  //     //    setTriggerErrorMessage(errorBody.message);
  //     //  } else if (errorBody.code === "005") {
  //     //    setTriggerWarningError(true);
  //     //    setTriggerWarningErrorMessage(errorBody.message);
  //     //  }
  //   }
  // }

  // Old request

  // const getSubscriberInfo = async (e) => {
  //   try {
  //     const url = `https://zpryy1jgve.execute-api.us-east-1.amazonaws.com/dev/insuranceinfo`;
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         phoneNumber: userPhone ? userPhone : localStorage.getItem("phone"),
  //         imei: userImei ? userImei : localStorage.getItem("imei"),
  //       }),
  //     });

  //     const data = await response.json();
  //     console.log("SUBS INFO ", data);
  //     dispatch({ type: "SET_BRAND", payload: data.device_brand });
  //     localStorage.setItem("brand", data.device_brand);

  //     dispatch({ type: "SET_MODEL", payload: data.device_model });
  //     localStorage.setItem("brand", data.device_brand);

  //     dispatch({ type: "SET_SUBSCRIBER_IMEI", payload: data.device_imei });
  //     localStorage.setItem("subscriberIMEI", data.device_imei);

  //     dispatch({ type: "SET_SUBSCRIBER_NAME", payload: data.firstName });
  //     localStorage.setItem("subscriberName", data.firstName);

  //     dispatch({
  //       type: "SET_SUBSCRIBER_FIRST_LAST_NAME",
  //       payload: data.firstLastName,
  //     });
  //     localStorage.setItem("subscriberFirstLastName", data.firstLastName);

  //     dispatch({
  //       type: "SET_SUBSCRIBER_SECOND_LAST_NAME",
  //       payload: data.secondLastName,
  //     });
  //     localStorage.setItem("subscriberSecondLastName", data.secondLastName);
  //   } catch (error) {
  //     throw new Error("An error occurred while validating IMEI", error);
  //   }
  // };

  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
  }, []);

  return (
    <section className="relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5 tablet:pt-8">
        <button onClick={() => navigate("/cotizacion")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>

      <Banner />
      {/* {isLoading ? (
        <Spinner />
      ) : ( */}
      <div className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10 overflow-scroll phone:mx-3">
        <h1 className="laptop:text-2xl phone:text- text-primary font-semibold laptop:py-6 text-center">
          Completa tus datos y elige una forma de pago
        </h1>
        <InsuranceDataModal />
      </div>
      {/* )} */}
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}
    </section>
  );
}
