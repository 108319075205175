import React, { useRef, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import desktopHomeImage from "../assets/images/desktopHome.jpg";
import newDesktopHomeImage from "../assets/images/new-desktop-img.jpg";
import piezasLogo from "../assets/images/Piezas.png";
import reparacionesLogo from "../assets/images/Reparaciones.png";
import pagoInicialLogo from "../assets/images/PagoInicial.png";

export default function Home() {
  const navigate = useNavigate();
  const targetDivRef = useRef(null);

  // Step 3: Create a function to handle the button click
  const scrollToDiv = () => {
    // Use the scrollIntoView method to scroll to the target div
    targetDivRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleClick = (e) => {
    if (e.target.name === "insurance") navigate("/quiero-proteccionmovil");
    else if (e.target.name === "certificate")
      navigate("/ya-tengo-proteccionmovil");
    // else if (e.target.name === "distributor") navigate("/distributorEntry");
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <section className="font-gotham h-full grid grid-cols-1 laptop:grid-cols-8">
      <div className="laptop:col-span-4">
        <img
          src={newDesktopHomeImage}
          className="laptop:w-[100%] laptop:h-[100%] phone:w-full phone:h-full"
        />
      </div>

      <div className="flex flex-col items-center laptop:col-span-4 ">
        <h1 className="laptop:mt-12 phone:mt-2 laptop:text-4xl phone:text-2xl text-center text-primary font-semibold phone:px-2 laptop:py-0">
          ¡Despreocúpate
          <br /> al estrenar tu smartphone!
        </h1>
        {/* <button
          onClick={scrollToDiv}
          name="insurance"
          className="shadow-lg text-md laptop:hidden text-white bg-blue-500 text-end font-thin cursor-pointer mx-2 mt-4 mb-4 border-2 border-sky-500 rounded-full py-1 px-2"
          //onClick={handleClick}
        >
          ¡Contrata ya!
        </button> */}
        <article className="laptop:mt-12 text-center laptop:px-6 phone:px-12 py-2 phone:mb-2 laptop:pt-[0rem] text-[#6D80A4] font-thin phone:text-sm laptop:text-xl laptop:text-2xl laptop:w-[45vw]">
          <p>
            Sabemos que los accidentes pasan y es por eso que al comprar un
            equipo en <span className="font-bold">Amigo Kit</span> te ofrecemos
            la opción de <span className="font-extrabold">proteger</span> su
            pantalla en caso de <span className="font-extrabold">ruptura</span>{" "}
            o <span className="font-extrabold">daño</span>.
          </p>
        </article>
        {/* <div className="flex grid grid-cols-3 justify-center items-center gap-3 px-10 py-3 laptop:py-8 text-center text-[0.8rem] phone:text-sm text-[#6D80A4] laptop:text-base">
          <div className="flex flex-col flex-wrap justify-center items-center px-3">
            <div className="w-16 h-16 bg-lightBlue rounded-full">
              <img src={piezasLogo} alt="piezas" />
            </div>
            <p className="py-3">
              Hasta <br />
              <span className="font-medium">dos reparaciones</span> <br /> en 12
              meses
            </p>
          </div>
          <div className="flex flex-col flex-wrap justify-center items-center px-3">
            <div className="w-16 h-16 bg-lightBlue  rounded-full">
              <img src={reparacionesLogo} alt="pagoInicial" />
            </div>
            <p className="py-3">
              Haz un
              <br /> <span className="font-medium">pago inicial</span>
              <br /> de contratación
            </p>
          </div>
          <div className="flex flex-col flex-wrap justify-center items-center px-3">
            <div className="w-16 h-16 bg-lightBlue rounded-full">
              <img src={pagoInicialLogo} alt="reparaciones" />
            </div>
            <p className="py-3">
              Reparaciones
              <br />
              <span className="font-medium">con piezas</span>
              <br />
              <span className="font-medium">originales</span>
              <br />
              <p className="desktop:hidden invisible">t</p>
            </p>
          </div>
        </div> */}
        <div className="flex flex-col">
          <div className="flex grid grid-cols-3 justify-items-center phone:gap-10 laptop:gap-16 px-10 laptop:pt-8 text-center phone:text-sm text-[#6D80A4] laptop:text-base">
            <div className="w-16 h-16 bg-lightBlue rounded-full">
              <img src={reparacionesLogo} alt="pagoInicial" />
            </div>
            <div className="w-16 h-16 bg-lightBlue rounded-full">
              <img src={pagoInicialLogo} alt="reparaciones" />
            </div>
            <div className="w-16 h-16 bg-lightBlue rounded-full">
              <img src={piezasLogo} alt="piezas" />
            </div>
          </div>
          <div className="flex grid grid-cols-3 justify-items-center items-center phone:gap-10 laptop:gap-20 px-10 laptop:mb-4 text-center text-[0.8rem] phone:text-xs text-[#6D80A4] laptop:text-base">
            <div>
              <p className="py-3">
                Hasta <br />
                <span className="font-medium">
                  dos reparaciones
                </span> <br /> en 12 meses
              </p>
            </div>
            <div>
              <p className="py-3">
                Haz un
                <br /> <span className="font-medium">pago inicial</span>
                <br />
                de contratación
              </p>
            </div>
            <div>
              <p className="py-3">
                Reparaciones
                <br />
                <span className="font-medium">con piezas</span>
                <br />
                <span className="font-medium">originales</span>
              </p>
            </div>
          </div>
        </div>
        <article>
          <p className="hidden desktop:block  text-[#6D80A4] font-thin text-center laptop:text-sm laptop:w-[45vw] px-2 phone:px-20 phone:text-[0.6rem] max-[390px]:px-12">
            La <span className="font-medium">contratación</span> de este
            servicio solo se puede realizar dentro de <br /> los primeros{" "}
            <span className="font-medium">30 días naturales </span>
            después de haber adquirido tu smartphone.
          </p>
        </article>
        <article>
          <p className="desktop:hidden text-[#6D80A4] font-thin text-center laptop:text-sm laptop:w-[45vw] px-2 phone:px-16 phone:mt-2 phone:text-[0.7rem] max-[390px]:px-12">
            La <span className="font-medium">contratación</span> de este
            servicio solo se puede realizar dentro de los primeros{" "}
            <span className="font-medium">30 días naturales </span>
            después de haber adquirido tu smartphone.
          </p>
        </article>
        <div
          ref={targetDivRef}
          className="laptop:mt-12 phone:mb-4 flex flex-col pt-4 text-sm laptop:text-base laptop:py-5"
        >
          <button
            name="insurance"
            className="shadow-lg font-thin bg-orng hover:bg-orange-700 laptop:w-72 laptop:text-lg text-white font-thin py-1 px-4 rounded-md laptop:py-3 laptop:px-6"
            onClick={handleClick}
          >
            Quiero Protección Móvil
          </button>
          <button
            name="certificate"
            onClick={handleClick}
            className="laptop:mt-2 phone:mt-1 py-2 text-[#006BBC] text-center font-base py-1 px-4 rounded-md laptop:py-3 laptop:px-6 hover:text-[#BDE3FF]"
          >
            Ya tengo Protección Móvil
          </button>
        </div>
      </div>
    </section>
  );
}

{
  /* <div className="phone:row-span-1 laptop:col-span-2 laptop:h-screen flex items-center justify-center bg-red-500 phone:h-[35vh] phone:w-full">
  <img
    src={protectMovilImg}
    alt="home"
    className="laptop:w-full h-full phone:w-full phone:object-none phone:object-top "
  />
</div>; */
}
