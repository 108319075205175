import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import protectMovilImg from "../assets/images/protec_movil_logo.png";
import mobileBanner from "../assets/images/mobile-banner.png";
import Selector from "../components/sharedComponents/Selector";
import Banner from "../components/sharedComponents/Banner";
export default function DistributorSelection() {
  const [distributor, setDistributor] = useState("");
  const [distErrorMsg, setDistErrorMsg] = useState("");

  const handleInputChange = (e) => {
    setDistributor(e.target.value);
  };

  const validation = (name, value) => {
    let phoneRegex = /[0-9]{10}/;
    let imeiRegex = /[0-9]{15}/;

    if (name === "phone") {
      return phoneRegex.test(value);
    } else if (name === "imei") {
      return imeiRegex.test(value);
    }
  };

  // const handleFormSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     console.log("Submit Triggered");

  //   } catch (err) {
  //     console.log(err);
  //     throw new Error("An error occurred while submitting the form");
  //   }
  // };

  // const inputs = [
  //   {
  //     id: "1",
  //     type: "text",
  //     name: "phoneNumber",
  //     label: "Número telcel",
  //     errorMessage: "Should contain at least 10 digits",
  //     required: true,
  //     pattern: "[0-9]{10}",
  //   },
  //   {
  //     label: "IMEI",
  //     name: "imei",
  //     id: "1",
  //     type: "text",
  //     errorMessage: "Should contain at least 18 digits",
  //     required: true,
  //   },
  // ];

  return (
    <section className="min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {/* <div className="phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 laptop:h-screen phone:h-1/8 overflow-hidden">
        <img
          src={protectMovilImg}
          alt="home"
          className="laptop:w-full h-full phone:h- object-cover "
        />
      </div> */}

      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}
      <form
        // onSubmit={handleFormSubmit}
        className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10"
      >
        <h1 className="text-2xl md:text-3xl text-primary font-semibold lg:py-6">
          Elige tu fuerza de ventas:
        </h1>

        <div className="flex flex-col justify-start items-start text-left pt-10 laptop:mt-8">
          <label
            htmlFor="name"
            className="text-base text-primary mb-2 font-thin"
          >
            Fuerza de ventas
          </label>
          <Selector />

          {/* {distErrorMsg ? (
            <span className="w-80 h-10 text-red-500 text-xs pt-2 rounded-md px-2">
              {distErrorMsg}
            </span>
          ) : null} */}
        </div>

        <div className="text-sm laptop:text-base laptop:py-[1rem] text-center">
          <button
            type="submit"
            className=" font-thin bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-48"
          >
            Continuar
          </button>
        </div>
      </form>
    </section>
  );
}
