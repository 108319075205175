import React, { useState } from "react";
import { useSelector } from "react-redux";
export default function SmartphoneData() {
  const imei = localStorage.getItem("subscriberIMEI");
  const brand = localStorage.getItem("brand");
  const model = localStorage.getItem("phoneModel"); //useSelector((state) => state.model);

  return (
    <div className="flex flex-col items-start my-1 bg-[#fff7e9] text-primary">
      <div className="flex flex-row items-center justify-center my-2">
        <p className="mx-[2rem]">IMEI:</p> <p>{imei}</p>
      </div>
      <div className="flex flex-row items-center justify-center my-2">
        <p className="mx-[2rem]">Marca:</p> <p>{brand}</p>
      </div>
      <div className="flex flex-row items-center justify-center my-2">
        <p className="mx-[2rem]">Modelo:</p> <p>{model}</p>
      </div>
    </div>
  );
}
