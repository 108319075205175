import React from "react";
import { Navigate } from "react-router-dom";
import protectMovilImg from "../assets/images/protec_movil_logo.png";
import Banner from "../components/sharedComponents/Banner";
export default function InsuranceEntryTicketUpload() {
  return (
    <section className="min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {/* <div className="phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 laptop:h-screen phone:h-1/8 overflow-hidden">
        <img
          src={protectMovilImg}
          alt="home"
          className="laptop:w-full h-full phone:h- object-cover "
        />
      </div> */}

      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}
      <div className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10">
        <h1 className="text-2xl md:text-3xl text-primary font-semibold lg:py-6">
          Ingresa los siguientes datos:
        </h1>

        <div className="flex flex-col justify-start items-start text-left pt-10">
          <label htmlFor="name" className="text-base text-primary mb-2">
            Número Telcel
          </label>
          <input
            type="text"
            id="name"
            disabled
            className="w-80 h-10 border-2 border-gray-300 rounded-md px-1"
            placeholder="5510101010"
          />
        </div>

        <div className="flex flex-col justify-start items-start text-left pt-10">
          <div>
            <label htmlFor="name" className="text-base text-primary mb-2">
              IMEI
            </label>
            <div className="relative inline-block group px-1">
              <span className="flex items-center justify-center w-4 h-4 bg-primary text-white rounded-full cursor-pointer text-xs">
                i
              </span>
              <div className="absolute hidden group-hover:block -top-1/2 left-full ml-2 w-48 p-2 bg-tooltip text-primary text-xs rounded shadow-lg">
                Para obtenerlo marca *#06# o escanea el código de barras que se
                encuentre en la caja del smartphone.
              </div>
            </div>
          </div>

          <input
            type="text"
            id="name"
            disabled
            className="w-80 h-10 border-2 border-gray-300 rounded-md px-1"
            placeholder="7589035678920"
          />
        </div>

        <div className="flex flex-col justify-start items-start text-left pt-10">
          <label htmlFor="name" className="text-base text-primary mb-2">
            Ticket de compra
          </label>
          <input
            type="file"
            id="imageUpload"
            className="hidden"
            //onChange={handleFileChange}
            accept="image/*"
          />
          <label
            htmlFor="imageUpload"
            //onDrop={handleDrop}
            //onDragOver={handleDragOver}
            className="w-80 h-10 border-2 border-gray-300 rounded-md px-1 bg-[#EEEFF2] flex items-center justify-center text-gray-500 cursor-pointer"
          >
            Arrastra una imagen o <span className="invisible">1</span>
            <span className="text-blue-500">sube un archivo</span>
          </label>
        </div>

        <div className="pt-8 text-xs laptop:text-base laptop:py-[5rem] text-center">
          <button className=" font-thin bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-48">
            Continuar
          </button>
        </div>
      </div>
    </section>
  );
}
