import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import { useSelector } from "react-redux";
import { GoArrowLeft } from "react-icons/go";
import Auth from "../utils/auth";
export default function CashPaymentConfirmation() {
  const username = useSelector((state) => state.titular);
  const titular = username ? username : localStorage.getItem("cash_titular");
  const cashAmount = localStorage.getItem("cash_amount");
  const lastFourDigits = localStorage.getItem("lastFourDigits");
  const reference = localStorage.getItem("cash_id");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize your variable
  const handleContinueClick = async (e) => {
    e.preventDefault();
    navigate("/home");
  };

  const expireAt = localStorage.getItem("expirationDate"); // 2024-10-05 16:13"
  const date = new Date(expireAt);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = date.toLocaleDateString("es-ES", options);

  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); // Set your variable to false after 5 seconds
    }, 5000);

    // Cleanup function to clear the timer if the component is unmounted before the 5 seconds are up
    return () => clearTimeout(timer);
  }, []);

  // {isLoading ? (
  //       <Spinner />
  //     ) : ()}

  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {/* <div className="phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 laptop:h-screen phone:h-1/8 overflow-hidden">
        <img
          src={protectMovilImg}
          alt="home"
          className="laptop:w-full h-full phone:h- object-cover "
        />
      </div> */}
      {/* <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5">
        <button onClick={() => navigate("/home")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div> */}

      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  justify-center item-center phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl  text-primary font-semibold phone:px-8 text-center mb-8">
            ¡Estás muy cerca de proteger tu equipo!
          </h1>

          <div className="flex flex-col justify-start items-center text-left pt-4 text-center text-center font-thin px-6 laptop:w-[40vw]">
            <p className="text-xl text-[#6D80A4]">
              Acude a cualquier tienda OXXO con la referencia que recibirás por
              correo electrónico y realiza el pago en efectivo
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Nombre: </span> {titular}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold"> Correo electrónico: </span> {email}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold"> Monto a pagar: </span>${cashAmount}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Referencia: </span> {reference}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin phone:px-[2.5rem]">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold laptop:text-xl phone:text-base">
                  Fecha límite de pago:
                </span>{" "}
                <span className="laptop:text-xl phone:text-sm">
                  {formattedDate}
                </span>
              </p>
            </div>
            <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
              <button
                onClick={handleContinueClick}
                className=" font-thin bg-orange-500 hover:bg-orng text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-48"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
