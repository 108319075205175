import React from "react";
import telcelLogo from "../../assets/images/telcelsvg.png";
const Footer = () => {
  return (
    <footer className="laptop:bottom inset-x-0 bottom-0 text-primary py-2 text-[0.5rem] bg-white phone:px-3 laptop:px-8">
      <div className=" mx-auto flex flex-col phone:flex-row justify-between items-center font-bold">
        <div className="phone:hidden laptop:block mb-2 phone:mb-0 text-center">
          <p className="laptop:text-[0.7rem]">
            © {new Date().getFullYear()} Radiomóvil Dipsa S.A de C.V. Todos los
            derechos reservados
          </p>
        </div>

        <div className="laptop:hidden mb-2 phone:mb-0 text-center">
          <p className="laptop:text-[0.7rem]">
            @ {new Date().getFullYear()} Radiomóvil Dipsa S.A de C.V.
          </p>
        </div>
        <a
          href="https://www.telcel.com/aviso-de-privacidad"
          target="_blank"
          className="hover:text-gray-400 text-center laptop:text-[0.7rem]"
        >
          Aviso de Privacidad
        </a>
        <a
          href="https://www.telcel.com/personas/servicios/localizacion-y-seguridad/proteccion-movil-amigo-kit"
          target="_blank"
          className="hover:text-gray-400 text-center laptop:text-[0.7rem]"
        >
          Términos <br className="laptop:hidden" /> y Condiciones
        </a>

        <a href="#" className="ml-2 hover:text-gray-400 font-bold">
          <img
            src={telcelLogo}
            alt="telcel logo"
            className="phone:w-[3rem] phone:h-[2rem] laptop:w-[5rem] laptop:h-[3rem]"
          />
        </a>
        {/* <div className="flex grid-cols-3 gap-4 items-center">
          <a href="#" className="hover:text-gray-400">
            Aviso de privacidad
          </a>
          <a href="#" className="hover:text-gray-400">
            Términos y condiciones
          </a>

          <a href="#" className="hover:text-gray-400 font-bold">
            <img
              src={telcelLogo}
              alt="telcel logo"
              className="w-[3rem] h-[2rem]"
            />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
