import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import WarningModal from "../components/sharedComponents/WarningModal";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import protegido from "../assets/images/protegido.png";
import { GoArrowLeft } from "react-icons/go";
import warningLogo from "../assets/images/warningLogo.png";
import denegated from "../assets/images/denegated.png";
import { post } from "aws-amplify/api";
import Auth from "../utils/auth";
const API_URL = process.env.REACT_APP_API_URL;
export default function InsuranceEntry() {
  const [isLoading, setIsLoading] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [triggerWarningError, setTriggerWarningError] = useState(false);
  const [trigerErrorMessage, setTriggerErrorMessage] = useState("");
  const [trigerWarningErrorMessage, setTriggerWarningErrorMessage] =
    useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ phoneNumber: "", imei: "" });
  const [otpSent, setOtpSent] = useState(false);
  const [policyException, setPolicyException] = useState(false);
  const [focused, setFocused] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [imeiErrorMsg, setImeiErrorMsg] = useState("");
  const [enableBtn, setEnableBtn] = useState(true);

  const validation = (name, value) => {
    let phoneRegex = /^[0-9]{10}$/;
    let imeiRegex = /^[0-9]{15}$/;

    if (name === "phone") {
      return phoneRegex.test(value);
    } else if (name === "imei") {
      return imeiRegex.test(value);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      validation("phone", value)
        ? setPhoneErrorMsg("")
        : setPhoneErrorMsg("Por favor, proporciona un número válido.");
    } else if (name === "imei") {
      validation("imei", value)
        ? setImeiErrorMsg("")
        : setImeiErrorMsg("Por favor, proporciona un IMEI válido.");
    }
    //name === "phoneNumber" ? setPhoneErrorMsg("") : setImeiErrorMsg("");
    setFormState({ ...formState, [name]: value });
  };

  async function checkImei() {
    console.log("validating");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/sendOTP",
        options: {
          body: {
            phoneNumber: formState.phoneNumber,
            imei: formState.imei,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log("OTP ", response);
      if (response.code === "0") {
        console.log("YEs ¡ ", response.token);
        dispatch({ type: "SET_PHONE_NUMBER", payload: formState.phoneNumber });
        localStorage.setItem("phone", formState.phoneNumber);
        dispatch({ type: "SET_IMEI", payload: formState.imei });
        localStorage.setItem("imei", formState.imei);
        localStorage.setItem("message", response.message);
        setOtpSent(true);
        Auth.login(response.token);
      }

      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      if (
        errorBody.code === "001" ||
        errorBody.code === "002" ||
        errorBody.code === "003" ||
        errorBody.code === "006"
      ) {
        setTriggerError(true);
        setTriggerErrorMessage(errorBody.message);
      } else if (errorBody.code === "005") {
        setTriggerWarningError(true);
        setTriggerWarningErrorMessage(errorBody.message);
      }
    }
  }

  useEffect(() => {
    if (
      validation("phone", formState.phoneNumber) &&
      validation("imei", formState.imei)
    ) {
      setEnableBtn(false); // enable the button
    } else {
      setEnableBtn(true); // disable the button
    }
  }, [formState.phoneNumber, formState.imei]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formState.phoneNumber || !validation("phone", formState.phoneNumber)) {
      setPhoneErrorMsg("Por favor, proporciona un número válido.");
      return;
    } else if (!formState.imei || !validation("imei", formState.imei)) {
      setImeiErrorMsg("Por favor, proporciona un IMEI válido.");
      return;
    } else {
      setIsLoading(true);
      checkImei().finally(() => setIsLoading(false));
    }
  };

  // if (otpSent) return <Navigate to={`/codigo-seguridad`} />;
  // else if (policyException) return <Navigate to={`/policyException`} />;
  return (
    <section className="font-gotham relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] tablet:pt-8 laptop:pt-5 phone:pt-5">
        <button
          onClick={() => {
            return navigate("/home");
          }}
        >
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={handleFormSubmit}
          className=" flex flex-col  phone:row-span-4  laptop:col-span-6 py-10"
        >
          <h1 className="laptop:text-2xl phone:text-xl text-primary text-center font-semibold lg:py-6">
            Ingresa los siguientes datos:
          </h1>

          <div className="flex flex-col justify-start items-start text-left pt-10">
            <label
              htmlFor="name"
              className="laptop:text-lg phone:text-md text-[#6D80A4] mb-2"
            >
              Número Telcel
            </label>

            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="10 dígitos"
              onChange={handleInputChange}
              value={formState.phoneNumber}
              className={`text-[#6D80A4] laptop:w-[28vw] phone:w-[75vw] h-10 border-2 ${
                phoneErrorMsg
                  ? "border-red-500"
                  : "border-[#6D80A4] border-opacity-50"
              } rounded-md px-2`}
            />

            {phoneErrorMsg ? (
              <div className="flex flex-row items-center justify-center pt-2">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-72 text-red-500 text-xs text-left">
                  {phoneErrorMsg}
                </span>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col justify-start items-start text-left pt-10">
            <div className="mb-2 flex flex-row justify-center items-center">
              <div>
                <label
                  htmlFor="name"
                  className="laptop:text-lg phone:text-md text-[#6D80A4] mb-2"
                >
                  IMEI
                </label>
              </div>

              <div className="relative inline-block group  ml-2">
                <span className="flex items-center text-center justify-center w-3 h-3 bg-[#6D80A4] text-white rounded-full cursor-pointer text-[0.6rem]">
                  i
                </span>
                <div className="absolute hidden group-hover:block -top-1/2 left-full ml-2 laptop:w-[22vw] phone:w-72 p-2 bg-tooltip text-[#6D80A4] text-xs rounded shadow-lg">
                  Para obtenerlo marca *#06# o encuéntralo en la caja de tu
                  smartphone.
                </div>
              </div>
            </div>

            {/* <div style={{ position: "relative" }}> */}
            {/* <img
                src={codigoBrras}
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                  height: "20px",
                  marginTop: "5px",
                }}
              /> */}
            <input
              type="text"
              id="imei"
              name="imei"
              placeholder="15 dígitos"
              onChange={handleInputChange}
              value={formState.imei}
              className={`text-[#6D80A4] laptop:w-[28vw] phone:w-[75vw] h-10 border-2 ${
                imeiErrorMsg
                  ? "border-red-500"
                  : "border-[#6D80A4] border-opacity-50"
              } rounded-md px-2`}
            />
            {/* </div> */}
            {/* <div className="flex flex-row items-center">
              <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
              <span className="w-72 text-red-500 text-xs pt-2 text-left">
                {errorMsg}
              </span>
            </div> */}
            {imeiErrorMsg ? (
              <div className="flex flex-row items-center justify-center pt-2">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-72 text-red-500 text-xs text-left">
                  {imeiErrorMsg}
                </span>
              </div>
            ) : null}
          </div>

          <div className="pt-8 text-sm laptop:text-base laptop:py-[5rem] text-center">
            <button
              type="submit"
              disabled={enableBtn}
              className={`shadow-md font-thin ${
                enableBtn
                  ? "bg-orange-300 cursor-not-allowed"
                  : "bg-orng hover:bg-orange-700 "
              } text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48`}
            >
              Continuar
            </button>
          </div>
        </form>
      )}
      <WarningModal
        isVisible={triggerWarningError}
        onClose={() => setTriggerWarningError(false)}
        color="warning"
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={protegido} alt="" className="text-center w-9 h-10" />
          </div>

          <h1 className="text-center text-primary font-semibold mb-2">
            Equipo Protegido
          </h1>
          <div className="w-auto rounded-full h-[0.3rem] bg-[rgba(255,193,7,0.25)] mb-3"></div>
          <p className="mb-4 text-base font-regular text-[#6D80A4] text-center">
            {trigerWarningErrorMessage}
          </p>
          {/* <p className="mb-4 text-lg font-regular text-primary text-center">
            Por favor, verifícalo e intenta de nuevo.
          </p> */}
          <div
            className="space-y-0 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setTriggerWarningError(false);
                // navigate("/");
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-md text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center phone:mr-2"
            >
              Cerrar
            </button>

            <button
              onClick={() => {
                setTriggerWarningError(false);
                navigate("/ya-tengo-proteccionmovil");
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center"
            >
              Ver certificado
            </button>
          </div>
        </div>
      </WarningModal>
      <CustomErrorModal
        isVisible={triggerError}
        onClose={() => setTriggerError(false)}
      >
        <div className="font-gotham flex flex-col items-center py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={denegated} alt="" className="text-center w-10 h-10" />
          </div>

          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Contratación no disponible
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <div className="flex justify-center tems-center laptop:w-[22vw] phone:w-64 ">
            <p className="  mb-4 text-base font-regular text-[#6D80A4] text-center">
              No es posible contratar Protección Móvil Amigo Kit. Para más
              información, visita un Centro de Atención a Clientes.
            </p>
          </div>

          <div
            className="space-y-6 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setTriggerError(false);
                navigate("/home");
              }}
              className="laptop:w-[10vw] phone:w-[50vw] tablet:w-[20vw] text-white bg-[#6D80A4] hover:bg-opacity-75 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-base px-5 phone:px-3 py-2 text-center"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CustomErrorModal>
    </section>
  );
}
