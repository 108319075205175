import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import candado from "../assets/images/candado.png";
import warningLogo from "../assets/images/warningLogo.png";
import { GoArrowLeft } from "react-icons/go";
import { post } from "aws-amplify/api";
import Auth from "../utils/auth";
const VALIDATE_OTP_API_URL = process.env.REACT_APP_VALIDATE_OTP_API_URL;
const API_URL = process.env.REACT_APP_API_URL;
export default function Verification() {
  const movil =
    useSelector((state) => state.phoneNumber) || localStorage.getItem("phone");
  const imei =
    useSelector((state) => state.imei) || localStorage.getItem("imei");
  const [counter, setCounter] = useState(300);
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;
  const displayTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  const [tries, setTries] = useState(0);
  //const { phoneNumber } = useParams();
  const lastFourDigits = movil ? movil.slice(-4) : "";
  const [showErrorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [enableBtn, setEnableBtn] = useState(true);

  //console.log("movil", movil);

  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    codeValidation(code) ? setEnableBtn(false) : setEnableBtn(true);
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter, code]);

  async function handleResendOtp() {
    console.log("validating");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/sendOTP",
        options: {
          body: {
            phoneNumber: movil,
            imei: imei,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log("OTP ", response);
      // if (response.code === "002" || response.code === "003") {
      //   setPolicyException(true);
      //   //setTriggerErrorMessage(response.message);
      // } else if (response.code === "001" || response.code === "005") {
      //   setTriggerError(true);
      //   setTriggerErrorMessage(response.message);
      // } else if (response.code === "0") {
      //   dispatch({ type: "SET_PHONE_NUMBER", payload: formState.phoneNumber });
      //   localStorage.setItem("phone", formState.phoneNumber);
      //   dispatch({ type: "SET_IMEI", payload: formState.imei });
      //   localStorage.setItem("imei", formState.imei);
      //   setOtpSent(true);
      // }

      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      // if (
      //   errorBody.code === "001" ||
      //   errorBody.code === "002" ||
      //   errorBody.code === "003"
      // ) {
      //   setTriggerError(true);
      //   setTriggerErrorMessage(errorBody.message);
      // } else if (errorBody.code === "005") {
      //   setTriggerWarningError(true);
      //   setTriggerWarningErrorMessage(errorBody.message);
      // }
    }
  }

  // const handleResendOtp = () => {
  //   fetch(
  //     "https://zpryy1jgve.execute-api.us-east-1.amazonaws.com/dev/sendOTP",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         phoneNumber: movil,
  //         imei: imei,
  //       }),
  //     }
  //   )
  //     .then((response) => {
  //       console.log("Status Code:", response.status);
  //       // if (response.status == 200) {
  //       //   setOtpSent(true);
  //       // }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Success:", data);
  //       // if (data == "OTP sent successfully!") {
  //       //   setOtpSent(true);
  //       // }
  //     })
  //     //.then(navigate("/otpVerification"))
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  async function validateCode() {
    console.log("validating code...");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/validateCode",
        options: {
          body: {
            code: code,
            phone: movil,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      // console.log("OTP ", response);
      // navigate("/cotizacion");
      if (response.status === "03") {
        console.log("Error while validating OTP");
        setErrorModal(true);
      } else if (response.status === "0") {
        console.log("Success while validating OTP");
        navigate("/cotizacion");
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      // navigate("/cotizacion");
      if (errorBody.status === "03") {
        console.log("Error while validating OTP");
        setErrorModal(true);
      }
    }
  }

  // const validateCode = async (e) => {
  //   try {
  //     const response = await fetch(
  //       "https://zpryy1jgve.execute-api.us-east-1.amazonaws.com/dev/validateCode",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           code: code,
  //           phone: movil,
  //         }),
  //       }
  //     );

  //     const data = await response.json();
  //     console.log("OTP validation... ", data);
  //     if (data.status === "03") {
  //       console.log("Error while validating OTP");
  //       setErrorModal(true);
  //       //setPolicyException(true);
  //     } else if (data.status === "0") {
  //       console.log("Success while validating OTP");
  //       //setOtpSent(true);
  //       navigate("/cotiza-proteccionmovil");
  //     }
  //   } catch (error) {
  //     throw new Error("An error occurred while validating IMEI", error);
  //   }
  // };

  const codeValidation = (value) => {
    let codeRegex = /^[0-9]{6}$/;
    return codeRegex.test(value);
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    validateCode();
  };

  const handleChange = (e) => {
    codeValidation(e.target.value)
      ? setErrorMsg("")
      : setErrorMsg(
          "El código que ingresaste no es válido, porfavor, revísalo e intenta de nuevo."
        );
    setCode(e.target.value);
  };
  if (!Auth.loggedIn()) {
    navigate("/home");
    return;
  }

  const buttonClass =
    counter === 0 && tries < 3
      ? "font-thin text-[#6D80A4] mt-3 font-bold py-2 px-4 rounded-md md:py-3 md:px-6"
      : "cursor-not-allowed font-thin mt-3 text-[#6D80A4] text-opacity-60 font-bold py-2 px-4 rounded-md md:py-3 md:px-6";
  return (
    <section className="relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5 tablet:pt-8">
        <button onClick={() => navigate("/quiero-proteccionmovil")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>
      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}
      <form
        onSubmit={handleCodeSubmit}
        className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10 "
      >
        <h1 className="laptop:text-2xl phone:text-base tablet:text-xl text-primary font-semibold laptop:py-6 text-center phone:px-8 laptop:px-0">
          Ingresa el código que te enviamos por SMS{" "}
          <br className="phone:hidden laptop:block" /> al número ********
          {lastFourDigits}
        </h1>

        <div className="flex flex-col justify-start items-center text-left pt-10 text-center">
          <div className="flex flex-col items-start">
            <label className="pb-2 text-[#6D80A4] laptop:text-lg">
              Código de seguridad
            </label>
            <input
              type="text"
              id="name"
              onChange={handleChange}
              value={code}
              placeholder="6 dígitos"
              className="laptop:w-96 phone:w-80 h-10 border-2 border-[#6D80A4] border-opacity-50 rounded-md px-2"
            />
          </div>

          {errorMsg ? (
            <div className="flex flex-row items-center">
              <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
              <span className="w-72 text-red-500 text-xs pt-2 text-left">
                {errorMsg}
              </span>
            </div>
          ) : null}
        </div>

        <div className="flex flex-col justify-center items-center pt-8 text-sm laptop:text-base laptop:py-[5rem] text-center">
          <button
            disabled={enableBtn}
            className={`shadow-md font-thin ${
              enableBtn
                ? "bg-orange-300 hover:bg-orange-300"
                : "bg-orng hover:bg-orange-700"
            } text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48`}
          >
            Continuar
          </button>
          <button
            className={buttonClass}
            type="button"
            onClick={() => {
              setCounter(300);
              handleResendOtp();
            }}
            disabled={counter !== 0}
          >
            {counter === 0
              ? "Generar otro código"
              : "Código vigente por " + displayTime}
            {/* Código vigente por ({displayTime}) */}
          </button>
        </div>
        {/* <div className="pt-8 text-sm laptop:text-base laptop:py-[5rem] text-center">
          <button className=" font-thin bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6">
            Enviar código nuevamente
          </button>
        </div> */}
      </form>
      <CustomErrorModal
        isVisible={showErrorModal}
        onClose={() => setErrorModal(false)}
        color="alert"
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={candado} alt="" className="text-center w-9 h-10" />
          </div>

          <h1 className="text-center text-primary font-semibold mb-2">
            Código no valido
          </h1>
          <div className="w-auto rounded-full h-[0.3rem] bg-alert bg-opacity-25 mb-3"></div>
          <p className="mb-4 text-base font-regular text-primary text-center">
            El código de verificación que ingresaste no es válido o ha expirado
          </p>
          {/* <p className="mb-4 text-lg font-regular text-primary text-center">
            Por favor, verifícalo e intenta de nuevo.
          </p> */}
          <div
            className="space-y-0 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setErrorModal(false);
                // navigate("/");
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-md text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center phone:mr-2"
            >
              Cerrar
            </button>

            <button
              onClick={() => {
                handleResendOtp();
                setCode("");
                setCounter(300);
                setErrorModal(false);
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center"
            >
              Generar código
            </button>
          </div>
        </div>
      </CustomErrorModal>
    </section>
  );
}
