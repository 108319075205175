import React from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
export default function PolicyException() {
  const navigate = useNavigate();

  const handleContinueClick = (e) => {
    navigate("/home");
  };
  return (
    <section className="min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {/* <div className="phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 laptop:h-screen phone:h-1/8 overflow-hidden">
        <img
          src={protectMovilImg}
          alt="home"
          className="laptop:w-full h-full phone:h- object-cover "
        />
      </div> */}
      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}
      <div className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10 ">
        <h1 className="text-2xl md:text-3xl text-primary font-semibold lg:py-6 text-center">
          Acude a un centro de atención a clientes
        </h1>

        <div className="flex flex-col justify-start items-center text-left pt-10 text-center text-center font-thin px-6">
          <p className="text-sm text-primary">
            Lo sentimos, no es posible realizar la contratación de Protección
            Móvil Amigo Kit por este medio.
          </p>
        </div>

        <div className="flex flex-col justify-start items-center text-left pt-10 text-center text-center font-thin px-6">
          <p className="text-sm text-primary">
            Por favor, visita un centro de Atención a cliente con tu ticket de
            compra para recibir ayuda de un Asesor.
          </p>
        </div>

        <div className="pt-8 text-sm laptop:text-base laptop:py-[5rem] text-center">
          <button
            onClick={handleContinueClick}
            className="shadow-md font-thin bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6"
          >
            Aceptar
          </button>
        </div>

        <div className="flex flex-col justify-start items-center text-left pt-10 text-center text-center font-thin px-6 cursor-pointer">
          <p className="text-xs text-primary">
            Ubica el centro de Atención a clientes más cercano{" "}
            <a
              target="_blank"
              href="https://www.telcel.com/personas/atencion-a-clientes/puntos-de-contacto/centro-atencion"
              className="text-blue-400"
            >
              aquí
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
}
