import React, { useEffect, useState } from "react";
import Accordion from "./Accordion";
import SmartphoneData from "./SmartphoneData";
import UserData from "./UserData";
import PaymentData from "./PaymentData";
export default function InsuranceDataModal() {
  const [userComplete, setUserComplete] = useState(false);
  useEffect(() => {
    const userDataComplete = localStorage.getItem("userDataComplete") || false;
    setUserComplete(userDataComplete);
  }, [userComplete]);
  return (
    <div className="laptop:overflow-auto laptop:h-[40vw] p-4 rounded-lg">
      <Accordion
        title="Datos del equipo"
        answer={<SmartphoneData />}
        bgColor="terciary"
        className="pt-2 border-b-4"
      />
      <Accordion
        title="Datos del titular"
        answer={<UserData />}
        bgColor={userComplete ? "terciary" : "orange-500"}
        className="pt-2 border-b-4"
      />
      <Accordion
        title="Forma de pago"
        answer={<PaymentData />}
        bgColor="orange-500"
        className="pt-2 border-b-4"
      />
    </div>
  );
}
