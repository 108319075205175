import React, { useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
//import { Navigate } from "react-router-dom";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import { post } from "aws-amplify/api";
import Auth from "../utils/auth";
import WarningModal from "../components/sharedComponents/WarningModal";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import protegido from "../assets/images/protegido.png";
import denegated from "../assets/images/denegated.png";
import Spinner from "../components/sharedComponents/Spinner";
const API_URL = process.env.REACT_APP_API_URL;
export default function DistributorEntry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { parameters } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState({ phoneNumber: "", imei: "" });
  const [otpSent, setOtpSent] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [trigerErrorMessage, setTriggerErrorMessage] = useState("");
  const [triggerWarningError, setTriggerWarningError] = useState(false);
  const [trigerWarningErrorMessage, setTriggerWarningErrorMessage] =
    useState("");
  const [policyException, setPolicyException] = useState(false);
  const [focused, setFocused] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [imeiErrorMsg, setImeiErrorMsg] = useState("");
  const [notAuthorized, setNotAuthorized] = useState(false);
  // const decodedString = atob(parameters);

  // const params = new URLSearchParams(parameters);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    name === "phoneNumber" ? setPhoneErrorMsg("") : setImeiErrorMsg("");
    setFormState({ ...formState, [name]: value });
  };

  async function validateDistributor() {
    console.log("validating");
    console.log("params: ", parameters);
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/distributorvalidation",
        options: {
          body: parameters,
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      if (response.code === "0") {
        console.log("Distributor validated");

        // mtd Changes
        localStorage.setItem("mtd", response.data);
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      setNotAuthorized(true);
      // console.log("Error body: ", errorBody);
      // navigate("/acude-a-cac");
      // if (errorBody.code === "023") {
      //   navigate("/acude-a-cac");
      // }
    }
  }

  useLayoutEffect(() => {
    // console.log("validating distributor: ", params);
    // const validateUser = async () => {
    //   console.log("Here goes nothing...");
    //   const soapEnvelope = `
    //     <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
    //                       xmlns:jax="http://jaxws.web.distribuidores.telcel.com.mx/">
    //       <soapenv:Header/>
    //       <soapenv:Body>
    //         <jax:requestAuthToken>
    //           <usuario>QWEASDEQWE213</usuario>
    //           <region>7</region>
    //           <token>BoDYeDSOQPW4UzpvpX8hjri</token>
    //         </jax:requestAuthToken>
    //       </soapenv:Body>
    //     </soapenv:Envelope>
    //   `;
    //   try {
    //     const response = await fetch(
    //       "http://www.telceltest85.telcel.com:9001/Portal-Distribuidores/ws_usuario?wsdl",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "text/xml;charset=UTF-8",
    //           SOAPAction: "",
    //         },
    //         body: soapEnvelope,
    //       }
    //     );
    //     if (!response.ok) {
    //       throw new Error("Failed to validate");
    //     }
    //     const textResponse = await response.text();
    //     const parser = new DOMParser();
    //     const xmlDoc = parser.parseFromString(textResponse, "text/xml");
    //     const codigo = xmlDoc.getElementsByTagName("codigo")[0]?.textContent;
    //     const mensaje = xmlDoc.getElementsByTagName("mensaje")[0]?.textContent;
    //     if (codigo === "0" && mensaje === "EXITO") {
    //       setValidated(true); // User is validated
    //       console.log("User is validated");
    //     } else {
    //       throw new Error("Invalid response");
    //     }
    //   } catch (error) {
    //     setError(error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    //validateUser();
    validateDistributor().finally(() => setIsLoading(false));
  }, []);

  const validation = (name, value) => {
    let phoneRegex = /[0-9]{10}/;
    let imeiRegex = /[0-9]{15}/;

    if (name === "phone") {
      return phoneRegex.test(value);
    } else if (name === "imei") {
      return imeiRegex.test(value);
    }
  };

  async function checkImei() {
    console.log("validating");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/sendOTP",
        options: {
          body: {
            phoneNumber: formState.phoneNumber,
            imei: formState.imei,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log("OTP ", response);
      if (response.code === "002" || response.code === "003") {
        setPolicyException(true);
        //setTriggerErrorMessage(response.message);
      } else if (response.code === "001" || response.code === "005") {
        setTriggerError(true);
        setTriggerErrorMessage(response.message);
      } else if (response.code === "0") {
        console.log("YEs ¡ ", response.token);
        dispatch({ type: "SET_PHONE_NUMBER", payload: formState.phoneNumber });
        localStorage.setItem("phone", formState.phoneNumber);
        dispatch({ type: "SET_IMEI", payload: formState.imei });
        localStorage.setItem("imei", formState.imei);
        localStorage.setItem("message", response.message);
        setOtpSent(true);
        Auth.login(response.token);
      }

      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      if (
        errorBody.code === "001" ||
        errorBody.code === "002" ||
        errorBody.code === "003"
      ) {
        setTriggerError(true);
        setTriggerErrorMessage(errorBody.message);
      } else if (errorBody.code === "005") {
        setTriggerWarningError(true);
        setTriggerWarningErrorMessage(errorBody.message);
      }
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formState.phoneNumber || !validation("phone", formState.phoneNumber)) {
      setPhoneErrorMsg("Plase provide a valid phone number");
      return;
    } else if (!formState.imei || !validation("imei", formState.imei)) {
      setImeiErrorMsg("Plase provide a valid IMEI");
      return;
    } else {
      dispatch({ type: "SET_PHONE_NUMBER", payload: formState.phoneNumber });
      checkImei();
    }
  };

  // if (otpSent) return <Navigate to={`/otpVerification`} />;
  // else if (policyException) return <Navigate to={`/policyException`} />;
  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <Banner />
      <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
        A-móvil
      </p>
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={handleFormSubmit}
          className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10"
        >
          <h1 className="laptop:text-2xl phone:text-xl md:text-3xl text-primary font-semibold lg:py-6">
            Ingresa los siguientes datos:
          </h1>

          <div className="flex flex-col justify-start items-start text-left pt-10">
            <label
              htmlFor="name"
              className="laptop:text-lg phone:text-md  text-primary mb-2"
            >
              Número Telcel
            </label>

            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              onChange={handleInputChange}
              value={formState.phoneNumber}
              className={`text-[#6D80A4] laptop:w-[28vw] phone:w-[75vw] h-10 border-2 ${
                phoneErrorMsg ? "border-red-500" : "border-gray-300"
              } rounded-md px-2`}
            />

            {phoneErrorMsg ? (
              <span className="text-red-500 text-xs pt-2">{phoneErrorMsg}</span>
            ) : null}
          </div>

          <div className="flex flex-col justify-start items-start text-left pt-10">
            <div className="mb-2 flex flex-row justify-center items-center">
              <div>
                <label
                  htmlFor="name"
                  className="laptop:text-lg phone:text-md text-primary mb-2"
                >
                  IMEI
                </label>
              </div>

              <div className="relative inline-block group px-1 ml-1">
                <span className="flex items-center justify-center w-3 h-3 bg-[#6D80A4] text-white rounded-full cursor-pointer text-[0.5rem]">
                  i
                </span>
                <div className="absolute hidden group-hover:block -top-1/2 left-full ml-2 laptop:w-[22vw] phone:w-72 p-2 bg-tooltip text-primary text-xs rounded shadow-lg">
                  Para obtenerlo marca *#06# o encuéntralo en la caja de tu
                  smartphone.
                </div>
              </div>
            </div>

            <input
              type="text"
              id="imei"
              name="imei"
              onChange={handleInputChange}
              value={formState.imei}
              className={`text-[#6D80A4] laptop:w-[28vw] phone:w-[75vw] h-10 border-2{
              imeiErrorMsg ? "border-red-500" : "border-gray-300"
            } rounded-md px-2`}
            />
            {imeiErrorMsg ? (
              <span className="text-red-500 text-xs pt-2">{imeiErrorMsg}</span>
            ) : null}
          </div>

          <div className="pt-8 text-sm laptop:text-base laptop:py-[5rem] text-center">
            <button
              type="submit"
              className="shadow-md font-thin bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-48"
            >
              Continuar
            </button>
          </div>
        </form>
      )}

      <WarningModal
        isVisible={triggerWarningError}
        onClose={() => setTriggerWarningError(false)}
        color="warning"
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={protegido} alt="" className="text-center w-9 h-10" />
          </div>

          <h1 className="text-center text-primary font-semibold mb-2">
            Equipo Protegido
          </h1>
          <div className="w-auto rounded-full h-[0.3rem] bg-[rgba(255,193,7,0.25)] mb-3"></div>
          <p className="mb-4 text-base font-regular text-primary text-center">
            {trigerWarningErrorMessage}
          </p>
          {/* <p className="mb-4 text-lg font-regular text-primary text-center">
            Por favor, verifícalo e intenta de nuevo.
          </p> */}
          <div
            className="space-y-0 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setTriggerWarningError(false);
                // navigate("/");
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-md text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center phone:mr-2"
            >
              Cerrar
            </button>

            <button
              onClick={() => {
                setTriggerWarningError(false);
                navigate("/ya-tengo-proteccionmovil");
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center"
            >
              Ver certificado
            </button>
          </div>
        </div>
      </WarningModal>
      <CustomErrorModal
        isVisible={setNotAuthorized}
        onClose={() => setNotAuthorized(false)}
      >
        <div className="font-gotham flex flex-col items-center py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={denegated} alt="" className="text-center w-10 h-10" />
          </div>

          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Distribuidor no autorizado
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <div className="flex justify-center tems-center laptop:w-[22vw] phone:w-64 ">
            <p className="  mb-4 text-base font-regular text-[#6D80A4] text-center">
              Lo sentimos, no estás autorizado para realizar esta operación.
            </p>
          </div>

          <div
            className="space-y-6 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setNotAuthorized(false);
                navigate("/home");
              }}
              className="laptop:w-[10vw] phone:w-[50vw] text-white bg-[#6D80A4] hover:bg-opacity-75 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-base px-5 phone:px-3 py-2 text-center"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CustomErrorModal>
    </section>
  );
}
