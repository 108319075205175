import React from "react";
import protectMovilImg from "../../assets/images/protec_movil_logo.png";
import mobileBanner from "../../assets/images/mobile-banner.png";
import desktopSidebar from "../../assets/images/desktopSidebar.jpg";
import pMovilAmigoKit from "../../assets/images/pMovilAmigoKit.png";
import mobileTopBanner from "../../assets/images/mobileTopBanner.jpg";
import { useNavigate } from "react-router-dom";
export default function Banner() {
  const navigate = useNavigate();
  return (
    <div
      className="phone:shadow-xl laptop:shadow-sm phone:row-span-1 laptop:col-span-2 laptop:h-screen flex items-center justify-center bg-terciary phone:h-full phone:w-full"
      style={{
        background: "rgb(255,224,162)",
        background:
          "linear-gradient(157deg, rgba(255,224,162,1) 21%, rgba(254,178,53,1) 55%)",
      }}
    >
      <img
        src={desktopSidebar}
        alt="Desktop Image"
        className="hidden desktop:block laptop:w-[100%] laptop:h-[100%] object-cover"
      />
      <img
        className="cursor-pointer absolute laptop:w-64 laptop:h-32 tablet:w-[50vw] tablet:h-[26vw] phone:w-56 phone:h-28 top-0 laptop:mt-8 phone:mt-8 tablet:mt-[8vw]"
        src={pMovilAmigoKit}
        onClick={() => navigate("/home")}
      />

      <img
        src={mobileTopBanner}
        alt="Mobile Image"
        className="block desktop:hidden w-full h-full object-center"
      />
    </div>
  );
}
