// store.js
import { createStore } from "redux";

const initialState = {
  phoneNumber: "",
  imei: "",
  subscriberImei: "",
  brand: "",
  brandModel: "",
  model: "",
  // color: "",
  policy: "",
  titular: "",
  subscriberName: "",
  subscriberFirstLastName: "",
  subscriberSecondLastName: "",
  openPaymentModal: false,
  certificates: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PHONE_NUMBER":
      return { ...state, phoneNumber: action.payload };
    case "SET_IMEI":
      return { ...state, imei: action.payload };
    case "SET_BRANDMODEL":
      return { ...state, brandModel: action.payload };
    case "SET_BRAND":
      return { ...state, brand: action.payload };
    case "SET_MODEL":
      return { ...state, model: action.payload };
    // case "SET_COLOR":
    //   return { ...state, color: action.payload };
    case "SET_POLICY":
      return { ...state, policy: action.payload };
    case "SET_TITULAR":
      return { ...state, titular: action.payload };
    case "SET_PAYMENT_MODAL":
      return { ...state, openPaymentModal: action.payload };
    case "SET_SUBSCRIBER_IMEI":
      return { ...state, subscriberImei: action.payload };
    case "SET_SUBSCRIBER_NAME":
      return { ...state, openPaymentModal: action.payload };
    case "SET_SUBSCRIBER_FIRST_LAST_NAME;":
      return { ...state, openPaymentModal: action.payload };
    case "SET_SUBSCRIBER_SECOND_LAST_NAME":
      return { ...state, openPaymentModal: action.payload };
    case "SET_CERTIFICATES":
      return { ...state, certificates: action.payload };

    default:
      return state;
  }
}

const store = createStore(reducer);

export default store;
